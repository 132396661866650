import React from 'react'
import './Progress.scss'

const Progress = ({ value, color = 'gray' }) => {
  return (
    <div className={`Progress`}>
      <div
        className={`Progress__bar Progress__bar--color--${color}`}
        style={{ width: `${value}%` }}
      ></div>
    </div>
  )
}

export default Progress
