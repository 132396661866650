import React from 'react'
import {useNavigate} from 'react-router-dom'
import {ReactComponent as IconBack} from '../../images/arrow-back.svg'
import Container from '../Container'
import Heading from '../Heading'
import IconButton from '../IconButton'
import Text from '../Text'
import './StepLayout.scss'

const StepLayout = ({
  variant = 'default',
  className = '',
  hasBack = false,
  onBack,
  size = 'lg',
  children,
}) => {
  let navigate = useNavigate()

  const handleBack = onBack
    ? onBack
    : () => {
        navigate('/');
      }

  return (
    <div className={`StepLayout StepLayout--${variant}`}>
      <Container size={size} className="StepLayout__container">
        {hasBack ? (
          <IconButton
            icon={<IconBack />}
            className="StepLayout__back"
            onClick={handleBack}
          />
        ) : null}
        <div className={`StepLayout__content ${className}`}>{children}</div>
      </Container>
    </div>
  )
}

const Title = ({ children }) => {
  return (
    <Heading as="h1" className="StepLayout__title">
      {children}
    </Heading>
  )
}

const Description = ({ children, ...props }) => {
  return (
    <Text className="StepLayout__description" {...props}>
      {children}
    </Text>
  )
}

const Footer = ({ children, isFloat }) => {
  return (
    <div
      className={`StepLayout__footer ${
        isFloat ? 'StepLayout__footer--isFloat' : ''
      }`}
    >
      {children}
    </div>
  )
}

StepLayout.Footer = Footer
StepLayout.Title = Title
StepLayout.Description = Description

export default StepLayout
