import axios from 'axios';
import DefaultResponseHandler from './ResponseHandler';
import { createClient } from "contentful";

const queryString = require('querystring');
const contentful = require('contentful');

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_CONTENTFUL_DOMAIN,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer g4ylYi7kNFEhc_ToEwgkv-8kYyraowB2nZXJGi_x9vM`
  }
});

const apiInstanceProfiler = axios.create({
  baseURL: process.env.REACT_APP_CONTENTFUL_DOMAIN,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer VIW7MDyVggnL7ie27H_ZAqND8jsPo6r6EPxJlboR1FE`
  }
});
/**
 * Contentful
 */
const apiController = `/spaces/v98oetbd7ayp/entries`;
const apiControllerProfiler = `/spaces/n64bibf2ruu3/entries`;
export const CONTENT_TYPES = {
  ActionStatuses: 'actionStatus',
  CheckInQuestions: 'checkInQuestions',
  SupportServices: 'supportServices',
  SetFocus: 'setFocus',
  Resources: 'article',
  Onboarding: 'onboardingCopy',
  FocusArea: 'focusAreas',
  Priorities: 'priorities',
  Actions: 'actions',
  About: 'aboutCopy',
  RateActions: 'rateActionQuickResponse',
  Review: 'reviewProgress',
  InspirationCarousel: 'inspirationCarousel',
  ProgressUpdate: 'progressUpdate',
  Factor: 'factor',
  subFactor: 'subFactor',
  TeamFactor: 'teamFactor',
  teamSubFactor: 'teamSubFactor',
  WelcomeMessage: 'modProfUserInfo',
  HoverCopy: 'hoverCopy',
  Content: 'modularProfilerContent',
  ChartDescription: 'modularProfilerChartDescriptions',
  TeamFactorItems: 'teamFactorItems'
};

const API_CONTENT = () => `${apiController}`;
const API_CONTENT_PROFILER = () => `${apiControllerProfiler}`;

let contentfulClient = null;
const cdn = () => {
  const client = {
    space: 'v98oetbd7ayp',
    accessToken: 'z6AIAvpQUt5adeE67RmFPXL1s8bYMzzRR5V2JB4Aw4Y',
  }
  // const createClientFunc = process.env.NODE_ENV === 'development' ? createClient : contentful.createClient
  if ( contentfulClient === null ) {
    contentfulClient = contentful.createClient
    ? contentful.createClient(client)
    : createClient(client)
  }
  return contentfulClient;
}

/**
 * Contentful
 */

let responseHandler;
const defaultResponseHandler = new DefaultResponseHandler();

/**
 * Utilities
 */
const executeRequest = (promise) => {
  return new Promise((resolve, reject) => {
    const handler =
      responseHandler && typeof responseHandler === 'function'
        ? responseHandler
        : defaultResponseHandler;
    promise
      .then((response) => {
        handler.handleSuccess(response, resolve, reject);
        responseHandler = undefined;
      })
      .catch((error) => {
        handler.handleError(error, reject);
        responseHandler = undefined;
      });
  });
};

const doGetRequest = (url, params, config) => {
  const organisationTag = window.localStorage.getItem('organisationTag') ?? 'default';

  if (params) {
    url += `?${queryString.stringify(params)}&limit=200&metadata.tags.sys.id[in]=default,${organisationTag}`;
  }
  return executeRequest(apiInstance.get(url, config));
};

const doGetRequestProfiler = (url, params, config) => {
  if (params) {
    url += `?${queryString.stringify(params)}&limit=200`;
  }
  return executeRequest(apiInstanceProfiler.get(url, config));
};

const doGetRequestWithTag = (url, params, config) => {
  const organisationTag = window.localStorage.getItem('organisationTag') ?? 'default';

  if (params) {
    url += `?${queryString.stringify(params)}&limit=200&metadata.tags.sys.id[in]=default,${organisationTag}`;
  }
  return executeRequest(apiInstance.get(url, config));
};



async function getWellbeingResourceArticle( contentfulEntryId ) {
  let response = null;
  let error = null;
  await cdn().getEntry( contentfulEntryId.trim() )
      .then((entry) => {
        console.log( "received result from Contentful API: " + entry )
        response = entry
      })
      .catch((err) => {
        console.error(err.message)
        error = err
        return error``
      })
  return response
}


/**
 * Utilities
 */

const getContentInPage = (contentType, config) => {
  return doGetRequest(
    API_CONTENT(),
    { content_type: contentType },
    config
  );
};

const getContentInPageProfiler = (contentType, config) => {
  return doGetRequestProfiler(
    API_CONTENT_PROFILER(),
    { content_type: contentType },
    config
  )
};


export const getContent = (contentType, config) => {
  return doGetRequest(API_CONTENT(), { content_type: contentType }, config);
};

export const getContentWithTag = (contentType, config) => {
  return doGetRequestWithTag(API_CONTENT(), { content_type: contentType }, config);
};

const exported = {
  apiInstance,
  CONTENT_TYPES,
  getContentInPage,
  getContentInPageProfiler,
  getContentWithTag,
  getWellbeingResourceArticle
};

export default exported;
