import { useQuery } from "@tanstack/react-query";
import ContentfulAPI from "../services/contenfulAPI";
import { useState } from "react";
import ScreenContentfulModel from "../models/ContentfulModel";

export default function useCheckInContent() {
    const [isLoading, setIsLoading] = useState(true);
    const [contentfulData, setContentfulData] = useState(null);

  const query = useQuery({
    queryKey: [ContentfulAPI.CONTENT_TYPES.CheckInQuestions],
    queryFn: async () =>
      ContentfulAPI.getContentInPage(
        ContentfulAPI.CONTENT_TYPES.CheckInQuestions
      )
        .then((response) => {
          try {
            if (!contentfulData) {
              response.data = ScreenContentfulModel.parseFromJson(
                response.data
              );
              setContentfulData(response.data.items);
              setIsLoading(response.statusCode !== 200 && !query.isFetching);
            }
          } catch (error) {
            console.error("Error parsing contentful data:", error);
            return Promise.reject(error); // Or handle error differently
          }
        }),
  });

  return {
    isLoading,
    contentfulData,
  };
}
