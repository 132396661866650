import React, {useState} from 'react';
import Button from '../../components/Button';
import CheckInSlider from '../../components/CheckInSlider/CheckInSlider';
import StepLayout from '../../components/StepLayout';
import './CheckinAll.scss';

const CheckinAll = React.memo(
  ({ contentfulData, initialData, onStepSubmit, onBack }) => {
    const { workWellbeing, workPerformance, lifePerformance, lifeWellbeing } =
      contentfulData || {};

    const [dirtyValues, setDirtyValues] = useState({
      workWellbeing: false,
      workPerformance: false,
      lifePerformance: false,
      lifeWellbeing: false
    });

    const [values, setValues] = useState(initialData);

    const handleSliderChange = ({ name, value }) => {
      setValues((s) => ({ ...s, [name]: value }));
      setDirtyValues((s) => ({ ...s, [name]: true }));
    };

    const onSubmit = () => {
      onStepSubmit && onStepSubmit(values);
    };

    const areAllTouched =
      Object.values(dirtyValues).filter((isDirty) => !isDirty).length === 0;

    return (
      <StepLayout onBack={onBack}>
        <StepLayout.Title>CHECK IN</StepLayout.Title>
        <StepLayout.Description>
          Click and drag on each of the sliders.
        </StepLayout.Description>

        <div className='CheckinAll__sliders'>
          <CheckInSlider
            title={lifeWellbeing?.headerText}
            description={lifeWellbeing?.bodyText}
            maxLabel={lifeWellbeing?.sliderMaxText}
            minLabel={lifeWellbeing?.sliderMinText}
            name='lifeWellbeing'
            defaultValue={values.lifeWellbeing.value}
            isDirty={values.lifeWellbeing.isDirty}
            onChange={handleSliderChange}
          />
          <CheckInSlider
            title={lifePerformance?.headerText}
            description={lifePerformance?.bodyText}
            maxLabel={lifePerformance?.sliderMaxText}
            minLabel={lifePerformance?.sliderMinText}
            defaultValue={values.lifePerformance.value}
            isDirty={values.lifePerformance.isDirty}
            name='lifePerformance'
            onChange={handleSliderChange}
          />
          <CheckInSlider
            title={workWellbeing?.headerText}
            description={workWellbeing?.bodyText}
            maxLabel={workWellbeing?.sliderMaxText}
            minLabel={workWellbeing?.sliderMinText}
            defaultValue={values.workWellbeing.value}
            isDirty={values.workWellbeing.isDirty}
            name='workWellbeing'
            onChange={handleSliderChange}
          />
          <CheckInSlider
            title={workPerformance?.headerText}
            description={workPerformance?.bodyText}
            maxLabel={workPerformance?.sliderMaxText}
            minLabel={workPerformance?.sliderMinText}
            defaultValue={values.workPerformance.value}
            isDirty={values.workPerformance.isDirty}
            name='workPerformance'
            onChange={handleSliderChange}
          />
        </div>
        <StepLayout.Footer isFloat>
          <Button onClick={onSubmit} disabled={!areAllTouched}>
            Submit
          </Button>
        </StepLayout.Footer>
      </StepLayout>
    );
  }
);

export default CheckinAll;
