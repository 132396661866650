import React, {useState} from 'react';
import {ReactComponent as IconInvisibility} from '../../images/icon-eye-off.svg';
import {ReactComponent as IconVisibility} from '../../images/icon-eye.svg';
import IconButton from '../IconButton';
import TextField from '../TextField';
import './PasswordField.scss';

const PasswordField = (props) => {
	const [isVisible, setVisible] = useState(false);

	const toggleVisible = () => {
		setVisible(!isVisible);
	}

	return (
		<TextField
			type={isVisible ? 'text' : 'password'}
			endAdornment={
				<IconButton 
					icon={isVisible ? <IconInvisibility /> : <IconVisibility />} 
					className='PasswordField__icon' 
					onClick={toggleVisible} 
				/>
			}
			{...props}
		/>
	)
}

export default PasswordField
