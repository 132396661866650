import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Heading from "../../../components/Heading";
import ReportProgress from "../../../components/ReportProgress";
import StepLayout from "../../../components/StepLayout";
import useCheckIn from "../../../hooks/useCheckIn";
import CheckInChartUtils from "../../../utils/checkinChart";
import "./Profiler.scss";
import Loading from "../../../components/Loading";
import useCheckInDetail from "../../../hooks/useCheckInDetail";
import { useAuth } from "../../../contexts/UserContext";
import HeatmapProfiler from "./components/HeatmapProfiler";
import isMobile from "../../../utils/isMobile";

const CheckinResults = React.memo(({ values, email, org, onStepSubmit }) => {
  const toogleChatBotEvent = new CustomEvent('toggle-chatbot');
  const {
    data: createCheckInResponse,
    createCheckIn,
    isSuccess: isCreateSuccess,
  } = useCheckIn();
  const { data: checkInDetail, getCheckInDetail } = useCheckInDetail();
  const [, setGraphData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [inter, setInter] = useState(null);
  const { user } = useAuth();
  const [heatmapData, setHeatmapData] = useState([]);
  const [bbIndexData, setBBIndexData] = useState([]);

  const bbIndexDataDefault = [
    {
      id: 1,
      title: "Life Wellbeing",
      description: "How satisfied are you with your level of wellbeing?",
      value: 0,
    },
    {
      id: 2,
      title: "Life Performance",
      description: "How well are you functioning in life outside of work?",
      value: 0,
    },
    {
      id: 3,
      title: "Work Wellbeing",
      description: "Are you finding work fulfilling?",
      value: 0,
    },
    {
      id: 4,
      title: "Work Performance",
      description: "Are you contributing well at work?",
      value: 0,
    },
  ];

  useEffect(() => {
    if (values) {
      //Diamond chart data
      const data = [
        {
          lifeWellbeing: 100,
          lifePerformance: 100,
          workWellbeing: 100,
          workPerformance: 100,
        },
        values,
      ];
      setGraphData(CheckInChartUtils.processData(data));

      //BBindex data
      bbIndexDataDefault[0].value = values.lifeWellbeing;
      bbIndexDataDefault[1].value = values.lifePerformance;
      bbIndexDataDefault[2].value = values.workWellbeing;
      bbIndexDataDefault[3].value = values.workPerformance;
      setBBIndexData(bbIndexDataDefault);

      //Heatmap data
      const performanceAverage = (
        (values.lifePerformance + values.workPerformance) /
        2
      ).toFixed(0);
      const wellbeingAverage = (
        (values.lifeWellbeing + values.workWellbeing) /
        2
      ).toFixed(0);
      const heatmap = [];
      const objHeatmap = {
        x: Number(performanceAverage),
        y: Number(wellbeingAverage),
      };
      heatmap.push(objHeatmap);
      setHeatmapData(heatmap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (isCreateSuccess) {
      const token = createCheckInResponse.data?.body?.token ?? "";
      getCheckInDetail(token);
    }
  }, [isCreateSuccess, getCheckInDetail, createCheckInResponse]);

  const doneSubmit = () => {
    // const checkpointRoute = process.env.REACT_APP_API_DOMAIN.includes(
    //   "dev.bennybutton"
    // )
    //   ? "https://bb-checkpoint-dev.web.app"
    //   : "http://checkpoint.bennybutton.com/check-auth";
    // let url = "";

    // if (userToken) {
    //   url = checkpointRoute + `/check-auth?token=${userToken}&target=profiler`;
    // } else {
    //   url = checkpointRoute + "/sign-up";
    // }

    // window.location.href = url;
    onStepSubmit()
  };

  useEffect(() => {
    let latitude = 0
    let longitude = 0
    const coords = localStorage.getItem("coords")
    if (coords) {
      const parseData = JSON.parse(coords)
      latitude = parseData.latitude
      longitude = parseData.longitude
    }
    setLoading(true);
    const orgPayload =
      user && user.data && user.data.organisationTag
        ? user.data.organisationTag
        : org ?? ""
    const payload = Object.assign({}, values);
    payload.email = user && user.email ? user.email : email;
    payload.organisation = orgPayload;
    if (latitude && longitude) {
      payload.latitude = latitude;
      payload.longitude = longitude;
    }
    createCheckIn(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (checkInDetail) {
      setLoading(false);
      const data = checkInDetail.data;
      const obj = {
        overall:
          data.dialogflowAnalysisClassificationDto.overall_interpretation,
        life: data.dialogflowAnalysisClassificationDto.life_interpretation,
        work: data.dialogflowAnalysisClassificationDto.work_interpretation,
      };
      setInter(obj);
      document.dispatchEvent(toogleChatBotEvent);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInDetail]);

  const renderInter = () => {
    return (
      <div style={{ paddingRight: 30 }}>
        <ul>
          <li>
            <Heading className="CheckinResults__interTitle" variant="lg">
              Your Overall Interpretation
            </Heading>
          </li>
          <Heading className="CheckinResults__interDescription" variant="md">
            {inter.overall}
          </Heading>
          <li>
            <Heading className="CheckinResults__interTitle" variant="lg">
              Your Life Interpretation
            </Heading>
          </li>
          <Heading className="CheckinResults__interDescription" variant="md">
            {inter.life}
          </Heading>
          <li>
            <Heading className="CheckinResults__interTitle" variant="lg">
              Your Work Interpretation
            </Heading>
          </li>
          <Heading className="CheckinResults__interDescription" variant="md">
            {inter.work}
          </Heading>
        </ul>
      </div>
    );
  };

  return (
    <StepLayout size="xlg" hasBack={false}>
      <StepLayout.Title>SUMMARY</StepLayout.Title>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="Profiler">
          <div className="Profiler__topSection">
            <div className="Profiler__heatmapWrap">
              <HeatmapProfiler data={heatmapData} />
            </div>
            <div className="Profiler__diamondChartWrap">
              <div className="WellbeingRisk__heatmapHeader">
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: isMobile() ? 6 : 0,
                  }}
                  className={isMobile() ? "lHeadline" : "xlHeadline"}
                >
                  Your Scores
                </p>
              </div>
              <div style={{ paddingLeft: 20, paddingRight: 0 }}>
                <div className="Profiler__listProgresses">
                  {bbIndexData.map((e, i) => (
                    <ReportProgress
                      isAnimated={e.isAnimated}
                      key={i}
                      title={e.title}
                      value={e.value}
                      description={e.description}
                      trending={""}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="Profiler__topSection">
            <div className="Profiler__factorsWrap">
              <div className="WellbeingRisk__heatmapHeader">
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: isMobile() ? 6 : 0,
                  }}
                  className={isMobile() ? "lHeadline" : "xlHeadline"}
                >
                  Your Interpretation
                </p>
              </div>
              {renderInter()}
            </div>
          </div>

          {isLoading ? null : (
            <>
              <div className="CheckinAll__desktopView">
                <StepLayout.Footer isFloat>
                  <Button onClick={doneSubmit}>{"Done"}</Button>
                </StepLayout.Footer>
              </div>
              <div className="CheckinAll__mobileViewDisplay">
                <Button
                  style={{ position: "relative", bottom: 0, top: 50 }}
                  className="CheckinAll__buttonSubmit"
                  onClick={doneSubmit}
                >
                  {"Done"}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </StepLayout>
  );
});

export default CheckinResults;
