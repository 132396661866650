import React from 'react'
import Box from '../Box'
import './Heading.scss'

const Heading = ({
  as = 'h2',
  variant = 'lg',
  className = '',
  gutterBottom,
  children,
  ...props
}) => {
  return (
    <Box
      as={as}
      className={`Heading Heading--variant--${variant} ${
        gutterBottom ? 'Heading--gutterBottom' : ''
      } ${className}`}
      {...props}
    >
      {children}
    </Box>
  )
}

export default Heading
