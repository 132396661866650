import React from 'react'
import { Dialog, DialogBackdrop, useDialogState } from 'reakit/Dialog'
import './Modal.scss'

export const useModal = useDialogState

const Modal = ({ children, onClose, ...props }) => {
  return (
    <DialogBackdrop
      className="Modal"
      {...props}
      onClick={() => {
        onClose?.()
      }}
    >
      <Dialog className="Modal__content" {...props}>
        {children}
      </Dialog>
    </DialogBackdrop>
  )
}

export default Modal
