import {useMutation} from "@tanstack/react-query";
import API from "../services/api";

export default function useCheckInDetail() {
    const mutation = useMutation({
        mutationFn: (id) => API.getCheckInDetail(id),
        onSuccess: (data) => {
            // Handle success
            console.log("Get check-in successfully:", data?.data);
        },
        onError: (error) => {
            // Handle error
            console.error("Error geting check-in:", error);
        },
    });

    const { data, mutate: getCheckInDetail, isSuccess, error } = mutation;

    return {
        data,
        getCheckInDetail,
        isSuccess,
        error,
    };
}