import React from 'react';
import Text from '../Text';
import './Form.scss';

export const Fieldset = ({ children }) => <fieldset>{children}</fieldset>;

export const Legend = ({ children }) => <Text as='legend'>{children}</Text>;


export const Form = ({ children, className = '', onSubmit, ...props }) => {


  React.useEffect(() => {
    const handleEnter = (event) => {
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        const form = event.target.form;
        const elements = Array.from(form.elements).filter(el => {
          return el.nodeName === 'INPUT' && !el.disabled
        })
        
        const index = Array.prototype.indexOf.call(elements, event.target);
        const nextEl = elements[index + 1];
  
        if (nextEl) {
          elements[index + 1]?.focus();
        } else {
          onSubmit?.()
        }
        
        event.preventDefault();
      }
    }

    document.addEventListener("keydown", handleEnter);

    return () => {
      document.removeEventListener('keydown', handleEnter);
    }
  }, [onSubmit]);

  return (
    <form className={`Form ${className}`} onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};

export default Form;
