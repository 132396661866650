import { useEffect, useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryPortal,
  VictoryScatter,
  VictoryLabel
} from "victory";
import { COLORS } from "../../../../config/colors";
import "../WellbeingRisk.scss";
import Loading from "../../../../components/Loading";
import isMobile from "../../../../utils/isMobile";

const axisLines = [
  { id: "drainersX", name: "Drainers", x: 5, color: COLORS.red },
  { id: "dividersX", name: "Dividers", x: 65, color: COLORS.amber },
  { id: "driversX", name: "Drivers", x: 75, color: COLORS.green },
  { id: "drainersY", name: "Drainers", y: 5, color: COLORS.red },
  { id: "dividersY", name: "Dividers", y: 65, color: COLORS.amber },
  { id: "driversY", name: "Drivers", y: 75, color: COLORS.green },
];

const headMaps = [
  { id: "drainersX", name: "Drainer", color: COLORS.red },
  { id: "dividersX", name: "Divider", color: COLORS.amber },
  { id: "driversX", name: "Driver", color: COLORS.green },
];

const CustomAxisLabel = (props) => {
  const { axis, x, y, style } = props;

  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject
        x={axis === "y" ? 52 : x - 6}
        y={axis === "x" ? 475 : y - 15}
        width={18}
        height={18}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill={style.fill}
          viewBox="0 0 16 16"
        >
          {axis === "x" ? (
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
          ) : (
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
          )}
        </svg>
      </foreignObject>
    </g>
  );
};

const HeatmapProfiler = ({ data, isLoading }) => {
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    if (data.length) {
      setCurrentData(data);
    }
  }, [data]);

  if (isLoading) {
    return <div style={{ paddingTop: 40 }}><Loading /></div>;
  }

  return (
    <>
      <div className="WellbeingRisk__heatmapHeader">
        <>
          <p style={{
            fontWeight: 'bold',
            marginTop: isMobile() ? 6 : 0
          }}
            className={isMobile() ? "lHeadline" : "xlHeadline"}>
            Check In Responses
          </p>
        </>
      </div>
      <div style={{
        height: isMobile() ? 420 : 600,
        width: isMobile() ? 390 : 600,
        paddingLeft: 30,
        paddingBottom: isMobile() ? 60 : 30
      }}>
        <VictoryChart
          width={550}
          height={550}
          domain={{ x: [0, 100], y: [0, 100] }}
        >
          <VictoryPortal>
            <VictoryAxis
              axisLabelComponent={
                <VictoryLabel
                  className='Wellbeing__label sLabel' dy={5}
                />}
              label="Work-Life Performance"
              tickCount={5}
              crossAxis
              style={{
                axisLabel: { padding: 40 },
                tickLabels: { fill: 'black' }
              }}
            />
          </VictoryPortal>
          <VictoryPortal>
            <VictoryAxis
              axisLabelComponent={
                <VictoryLabel
                  className='Wellbeing__label sLabel' dy={5}
                />}
              label="Work-Life Wellbeing"
              tickCount={5}
              dependentAxis
              crossAxis
              style={{
                axisLabel: { padding: 50 },
                tickLabels: { fill: 'black' }
              }}
            />
          </VictoryPortal>
          {axisLines.map(({ id, name, x, y, color }) => (
            <VictoryAxis
              key={id}
              dependentAxis={!!x}
              label={name}
              axisValue={x ?? y}
              axisLabelComponent={
                <CustomAxisLabel axis={x ? "x" : "y"} />
              }
              style={{
                axis: {
                  strokeWidth: x === 5 || y === 5 ? 0 : 1,
                  strokeDasharray: "2,4",
                },
                tickLabels: { fill: "none" },
                axisLabel: { padding: -5, fontSize: 5, fill: color },
              }}
            />
          ))}
          <VictoryScatter
            size={8}
            style={{
              data: {
                fill: (e) => {
                  return COLORS.blue
                },
                opacity: 1
              },
            }}
            data={currentData ?? []}
          />
        </VictoryChart>
        <div className="inlineHeat">
          {headMaps.map((heatMap) => (
            <div key={heatMap.color} id={heatMap.color} className="inlineHeat__marginRight">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill={heatMap.color}
                viewBox="0 0 16 16"
              >
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
              <span style={{ marginLeft: 10 }}>{heatMap.name}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HeatmapProfiler;
