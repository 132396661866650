import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "./contexts/UserContext";
import ChatBot from "./components/ChatBot/ChatBot";
import logoImage from "./components/ChatBot/assets/Benny-Button-favicon.png"

const ChatBotComponent = () => {
  const baseURL = process.env.REACT_APP_API_DOMAIN;

  const { userToken } = useAuth();
  /* eslint-disable no-unused-vars */
  const [aiUser, setAiUser] = useState(true);
  const [showChatBot, setShowChatBot] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [botSessionId, setBotSessionId] = useState('');
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    document.addEventListener(
      'hide-chatbot',
      (e) => setShowChatBot(false),
      false
    );
  },[])

  // useEffect(() => {
  //   setAiUser(baseURL && baseURL.toString()?.includes("api-dev.") );
  // }, [showChatBot, baseURL, userToken]);
  useEffect(() => {
    setAiUser(baseURL && ( baseURL.toString()?.includes("api-dev.") || baseURL.toString()?.includes("api.")) );
  }, [showChatBot, baseURL, userToken]);

  useEffect(() => {
    if (showChatBot) {
      axios.post(`${baseURL}v1/bot/dialogflow/start`, {}, {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }})
        .then((response) => {
          if (response.data.status === 201) {
            let body = JSON.parse(response.data.body);
            setBotSessionId(body.session_id);
          } else {
            setApiError('Session could not be started!!!');
          }
        })
        .catch((error) => {
          if (error.response) {
            setApiError(`Error: ${error.response.data}`);
            console.error(apiError + " : " + error.response.data);
          } else if (error.request) {
            setApiError('Network error occurred.');
            console.error(apiError + " : " + error.request);
          } else {
            setApiError(`Error: ${error.message}`);
            console.error(apiError + " : " + error.message);
          }
        });
    }
  }, [showChatBot, baseURL, userToken, apiError]);

  const toggleChatBot = () => {
    setShowChatBot(prevState => !prevState);
  };

  return <div className="chat-page">
    {(!apiError && aiUser === true) &&
      <div className={`chatbot-icon ${showChatBot ? 'active' : ''}`} onClick={toggleChatBot}>
        <div className='chatbot-icon-div'>
          <img src={logoImage} className="chatbot-icon" alt="ChatBot Icon" width={50} height={50} />
        </div>
      </div>}

    {apiError ? (
      <div className="error-message">{apiError}</div>
    ) : (
      showChatBot && <ChatBot onClose={toggleChatBot} session_id={botSessionId} />
    )}
  </div>
}

export default ChatBotComponent;