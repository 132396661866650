import React from 'react';
import Box from '../Box';
import './Avatar.scss';

const Avatar = ({ firstName = '', lastName = '', className = '' }) => {
  const abbr = firstName[0] + lastName[0];
  return <Box className={`Avatar ${className}`}>{abbr}</Box>;
};

export default Avatar;
