import React from 'react';
import Logo from '../../images/bb-logo-white.svg';
import Container from '../Container';
import Link from '../Link';
import Text from '../Text';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className='Footer'>
      <Container className='Footer__container'>
        <div>
          <Link className='logo' to='/'>
            <img src={Logo} alt='Benny Button' width={126} height={62} />
          </Link>
        </div>
        <div>
          <Text>
            © 2024, BennyButton. All rights reserved.
          </Text>
        </div>
        <nav className='Footer__nav'>
          <Link
            href='https://bennybutton.com/termsandconditions'
            target='_blank'
          >
            Terms & conditions
          </Link>
          <Link href='https://bennybutton.com/privacy' target='_blank'>
            Privacy Policy
          </Link>
          <Link href='https://bennybutton.com/security' target='_blank'>
            Security
          </Link>
          <Link href='https://bennybutton.com/cookies' target='_blank'>
            Cookie Statement
          </Link>
        </nav>
      </Container>
    </footer>
  );
};

export default Footer;
