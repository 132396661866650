import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Container from "../../components/Container";
import { Form, Legend } from "../../components/Form";
import Heading from "../../components/Heading";
import Link from "../../components/Link";
import Loading from "../../components/Loading";
import PasswordField from "../../components/PasswordField";
import TextField from "../../components/TextField";
import { useAuth } from "../../contexts/UserContext";
import API from "../../services/api";
import Validators from "../../utils/validators";
import "./Signup.scss";
import { useLocation } from "react-router-dom";
import Page from "../../components/Page";
// import { useState } from "react";
// import { SEPARATORS } from "react-tag-input";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./ReactTags.scss";

const REQUIRED_FIELD = "This field is required";
const MIN_LENGTH_FIELD = "8-characters minimum";
// const ReactTags = require("react-tag-input").WithOutContext;

const signupSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(REQUIRED_FIELD)
    .min(8, MIN_LENGTH_FIELD)
    .matches(Validators.PASSWORD_REGEX_MIN_SIZE_8, "8-character minimum"),
  rePassword: Yup.string()
    .required(REQUIRED_FIELD)
    .oneOf([Yup.ref("newPassword"), null], "Passwords don’t match"),
  email: Yup.string().required(REQUIRED_FIELD).email("Invalid email"),
  firstName: Yup.string().required("You must enter a first name"),
  lastName: Yup.string().required("You must enter a last name"),
});

const AccountExistMessage = () => (
  <div>
    Account already in use, <Link to="/sign-in">sign in</Link> here
  </div>
);

const initialData = {
  email: "",
  newPassword: "",
  rePassword: "",
  firstName: "",
  lastName: "",
};

const Signup = () => {
  const { login, isLoading: isLoginLoading } = useAuth();
  const search = useLocation().search;
  const signupKey = new URLSearchParams(search).get("signup-key");
  const organization = new URLSearchParams(search).get("organisation");
  // const [tags, setTags] = useState([]);

  // const suggestions = [
  //   { id: "Mental Health", text: "Mental Health" },
  //   { id: "Physical Activity", text: "Physical Activity"},
  //   { id: "Nutrition", text: "Nutrition" },
  //   { id: "Sleep Hygiene", text: "Sleep Hygiene" },
  //   { id: "Work-Life Balance", text: "Work-Life Balance" },
  //   { id: "Mindfulness and Meditation", text: "Mindfulness and Meditation"},
  //   { id: "Stress Management", text: "Stress Management" },
  //   { id: "Social Connections", text: "Social Connections" },
  //   { id: "Personal Growth", text: "Personal Growth" },
  // ];

  const mutation = useMutation({
    mutationFn: (data) => API.signup(data),
    onSuccess: () => {
      login({
        email: values.email,
        password: values.newPassword,
        isSignUp: true,
      });
    },
    onError: (error) => {
      console.error("Error geting check-in:", error);
    },
  });

  navigator.geolocation.getCurrentPosition((position) => {
    const coords = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    localStorage.setItem("coords", JSON.stringify(coords));
  });

  const {
    mutate: signup,
    error: signupError,
    isPending: isSignupLoading,
  } = mutation;

  const {
    values,
    errors,
    handleChange: handleFieldChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialData,
    validationSchema: signupSchema,
    onSubmit: ({ email, firstName, lastName, newPassword: password }) => {
      const tagParam = []
      // for (const i in tags) {
      //   tagParam.push(tags[i].text)
      // }
      signup({
        email,
        password,
        firstName,
        lastName,
        signupKey,
        organisationTag: organization ?? "default",
        isCheckinAppSignup: true,
        interestTags: tagParam.join(', ')
      });
    },
  });

  const handleChange = (event) => {
    handleFieldChange(event);
  };

  const isAccountExist = signupError?.statusCode === 409;

  if (isLoginLoading) {
    return <Loading isFullScreen />;
  }

  // const handleDeleteTag = (index) => {
  //   setTags(tags.filter((_, i) => i !== index));
  // };

  // const onTagUpdate = (index, newTag) => {
  //   const updatedTags = [...tags];
  //   updatedTags.splice(index, 1, newTag);
  //   setTags(updatedTags);
  // };

  // const handleAdditionTag = (tag) => {
  //   setTags((prevTags) => {
  //     return [...prevTags, tag];
  //   });
  // };

  return (
    <DndProvider backend={HTML5Backend}>
      <Page hideLogin>
        <Container className="Signup">
          <Heading as="h1" variant="xl" className="Signup__title">
            Sign Up
          </Heading>
          <Form className="Signup__form" onSubmit={handleSubmit}>
            <div>
              <Legend>Personal Details</Legend>
              <TextField
                id="firstName"
                label="First name *"
                value={values.firstName}
                error={errors.firstName}
                onChange={handleChange}
              />
              <TextField
                id="lastName"
                label="Last name *"
                value={values.lastName}
                error={errors.lastName}
                onChange={handleChange}
              />
              <TextField
                id="email"
                label="Email *"
                type="email"
                value={values.email}
                error={isAccountExist ? <AccountExistMessage /> : errors.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <Legend>Password</Legend>
              <PasswordField
                id="newPassword"
                label="New Password *"
                value={values.newPassword}
                error={errors.newPassword}
                onChange={handleChange}
              />
              <PasswordField
                id="rePassword"
                label="Reenter password *"
                value={values.rePassword}
                error={errors.rePassword}
                onChange={handleChange}
              />
              {/* <div style={{ marginTop: 30, marginBottom: 30 }}>
                <Legend>Your Wellbeing Experience</Legend>
                <ReactTags
                  tags={tags}
                  suggestions={suggestions}
                  separators={[SEPARATORS.ENTER, SEPARATORS.comma]}
                  handleDelete={handleDeleteTag}
                  handleAddition={handleAdditionTag}
                  onTagUpdate={onTagUpdate}
                  inputFieldPosition="bottom"
                  allowDragDrop={false}
                  minQueryLength={1}
                  placeholder="Please type in your wellbeing interests"
                />
              </div> */}
              <Button
                isFull
                size="lg"
                type="submit"
                disabled={isSignupLoading}
                className="Signup__submit"
              >
                Submit
              </Button>
              <Box className="Signup__footer">
                <p>
                  By clicking Submit, you agree to our{" "}
                  <Link
                    href="https://bennybutton.com/termsandconditions"
                    target="_blank"
                  >
                    Terms and Conditions
                  </Link>
                </p>
                <p>
                  Already have an account?{" "}
                  <Link to="/sign-in">Sign in here.</Link>
                </p>
              </Box>
            </div>
          </Form>
        </Container>
      </Page>
    </DndProvider>
  );
};

export default Signup;
