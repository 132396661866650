import React, { useRef, useState } from "react";
import CheckInSlider from "../../components/CheckInSlider/CheckInSlider";
import "../check-in/CheckinAll.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import IconNavigation from "../../images/arrow-up.svg";
import Button from "../../components/Button";

const CheckInMobileView = React.memo(
  ({ contentfulData, initialData, onStepSubmit, onBack }) => {
    const [slideStep, setSlideStep] = useState(0);

    const swiperRef = useRef();

    const totalStep = 3;

    const { workWellbeing, workPerformance, lifePerformance, lifeWellbeing } =
      contentfulData || {};

    const [dirtyValues, setDirtyValues] = useState({
      workWellbeing: false,
      workPerformance: false,
      lifePerformance: false,
      lifeWellbeing: false,
    });

    const [values, setValues] = useState(initialData);

    const handleSliderChange = ({ name, value }) => {
      setValues((s) => ({ ...s, [name]: value }));
      setDirtyValues((s) => ({ ...s, [name]: true }));
    };

    const onSubmit = () => {
      onStepSubmit && onStepSubmit(values);
    };

    const areAllTouched =
      Object.values(dirtyValues).filter((isDirty) => !isDirty).length === 0;

    const navigate = (type) => {
      const activeIndex = swiperRef.current.swiper.activeIndex;
      if (type === "up" && activeIndex > 0) {
        swiperRef.current.swiper.slideTo(activeIndex - 1);
      } else if (type === "down" && activeIndex < totalStep) {
        swiperRef.current.swiper.slideTo(activeIndex + 1);
      }
    };

    const renderSwiper = () => {
      return (
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          noSwiping={true}
          allowTouchMove={false}
          direction={"vertical"}
          className="CheckinAll__swiper"
          onSlideChange={(slide) => setSlideStep(slide.activeIndex)}
        >
          <SwiperSlide key={1}>
            <CheckInSlider
              title={lifeWellbeing?.headerText}
              description={lifeWellbeing?.bodyText}
              maxLabel={lifeWellbeing?.sliderMaxText}
              minLabel={lifeWellbeing?.sliderMinText}
              name="lifeWellbeing"
              defaultValue={values.lifeWellbeing.value}
              isDirty={values.lifeWellbeing.isDirty}
              onChange={handleSliderChange}
              classNameContainer="CheckinAll__slideContainer"
            />
          </SwiperSlide>
          <SwiperSlide key={2}>
            <CheckInSlider
              title={lifePerformance?.headerText}
              description={lifePerformance?.bodyText}
              maxLabel={lifePerformance?.sliderMaxText}
              minLabel={lifePerformance?.sliderMinText}
              defaultValue={values.lifePerformance.value}
              isDirty={values.lifePerformance.isDirty}
              name="lifePerformance"
              onChange={handleSliderChange}
              classNameContainer="CheckinAll__slideContainer"
            />
          </SwiperSlide>
          <SwiperSlide key={3}>
            <CheckInSlider
              title={workWellbeing?.headerText}
              description={workWellbeing?.bodyText}
              maxLabel={workWellbeing?.sliderMaxText}
              minLabel={workWellbeing?.sliderMinText}
              defaultValue={values.workWellbeing.value}
              isDirty={values.workWellbeing.isDirty}
              name="workWellbeing"
              onChange={handleSliderChange}
              classNameContainer="CheckinAll__slideContainer"
            />
          </SwiperSlide>
          <SwiperSlide key={4}>
            <CheckInSlider
              title={workPerformance?.headerText}
              description={workPerformance?.bodyText}
              maxLabel={workPerformance?.sliderMaxText}
              minLabel={workPerformance?.sliderMinText}
              defaultValue={values.workPerformance.value}
              isDirty={values.workPerformance.isDirty}
              name="workPerformance"
              onChange={handleSliderChange}
              classNameContainer="CheckinAll__slideContainer"
            />
          </SwiperSlide>
        </Swiper>
      );
    };

    return (
      <div className="CheckinAll__mobileViewContainer">
        {renderSwiper()}
        {slideStep === 3 ? (
          <Button
            className="CheckinAll__buttonSubmit"
            onClick={onSubmit}
            disabled={!areAllTouched}
          >
            Submit
          </Button>
        ) : null}
        <div className="CheckinAll__navigationWrap">
          <div
            onClick={() => navigate("up")}
            style={{ opacity: slideStep === 0 ? 0.4 : 1 }}
            className="CheckinAll__navigation"
          >
            <img alt="up-icon" src={IconNavigation} />
          </div>
          <div
            onClick={() => navigate("down")}
            style={{ opacity: slideStep === totalStep ? 0.4 : 1 }}
            className="CheckinAll__navigation"
          >
            <img
              alt="down-icon"
              style={{ transform: `rotate(180deg)` }}
              src={IconNavigation}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default CheckInMobileView;
