import React from 'react';
import Button from '../../components/Button';
import Container from '../../components/Container';
import StepLayout from '../../components/StepLayout';

const CheckinIntroduce = React.memo(
  ({ onStepSubmit, onBack, contentfulData = {}, onChangeSkip }) => {
    return (
      <StepLayout variant='primary' onBack={onBack}>
        <Container size='sm'>
          <StepLayout.Title>{contentfulData?.headerText}</StepLayout.Title>

          <StepLayout.Description
            dangerouslySetInnerHTML={{ __html: contentfulData?.bodyText }}
          />
          <StepLayout.Footer>
            <Button
              variant='secondary'
              size='lg'
              onClick={() => onStepSubmit()}
            >
              Start
            </Button>

          </StepLayout.Footer>
        </Container>
        <label className='StepLayout__skip'>
          <input onChange={() => onChangeSkip()} type='checkbox' defaultChecked={false} />
          <span>Skip this explainer in the future.</span>
        </label>
      </StepLayout>
    );
  }
);

export default CheckinIntroduce;
