import React from 'react';

const SecurePage = () => {
    return (
        <div>
            <h1>Secure Page</h1>
            <p>This page is only accessible after login.</p>
        </div>
    );
};

export default SecurePage;