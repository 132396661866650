import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Box from '../Box';
import './Link.scss';

const Link = ({ to, children, ...props }) => {
  return (
    <Box as={to ? RouterLink : 'a'} to={to} className='Link' {...props}>
      {children}
    </Box>
  );
};

export default Link;
