import React, { useState } from "react";
import Button from "../Button";
import "./Slider.scss";
import { Slider } from "@mui/material";

const SliderComponent = ({
  maxLabel,
  minLabel,
  onChange,
  minValue = 0,
  maxValue = 100,
  defaultValue = 0,
  className = "",
  dirty,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const handleChange = (val) => {
    const value = val.target.value;
    setValue(value);
    onChange?.(value);
  };

  const setMaxValue = () => {
    setValue(maxValue);
    onChange?.(maxValue);
  };

  const setMinValue = () => {
    setValue(minValue);
    onChange?.(minValue);
  };

  return (
    <div className={`Slider ${className}`}>
      {maxLabel ? (
        <Button variant="ghost" className="Slider__label" onClick={setMaxValue}>
          {maxLabel}
        </Button>
      ) : null}
      <Slider
        sx={{
          '& input[type="range"]': {
            WebkitAppearance: "slider-vertical",
          },
          color: "#00DE91"
        }}
        orientation="vertical"
        max={100}
        min={0}
        onChange={handleChange}
        value={value}
        aria-label="Temperature"
        valueLabelDisplay="auto"
        {...props}
      />
      {/* <ReactSlider
        orientation="vertical"
        invert
        pearling
        minDistance={1}
        max={100}
        min={0}
        onChange={handleChange}
        value={value}
        {...props}
      /> */}
      {minLabel ? (
        <Button variant="ghost" className="Slider__label" onClick={setMinValue}>
          {minLabel}
        </Button>
      ) : null}
    </div>
  );
};

export default SliderComponent;
