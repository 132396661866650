import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/UserContext";
import LogoSrc from "../../images/bb-logo.png";
/*
import HomeFactor from "../../images/home-factors.svg";
 */
import Button from "../Button";
import IconButton from "../IconButton";
import Link from "../Link";
import Navigation from "../Navigation";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import Avatar from "../Avatar";

const Header = ({ className = "", logo, navigation, toggle, hideLogin }) => {
  const hideChatBotEvent = new CustomEvent("hide-chatbot");
  const navigate = useNavigate();
  const { user, userToken, resetUser } = useAuth();
  const [isOpenProfile, toggleProfile] = useState(false);
  useEffect(() => {
    toggleProfile(false);
  }, [toggle, toggleProfile]);

  return (
    <header className={`Header ${className}`}>
      <div className="Header__content">
        <div
          className={`Header__logo ${
            userToken ? "Header__logo--notLogin" : ""
          }`}
          onClick={() => {
            toggleProfile(false);
          }}
        >
          <Link to="/">
            <img src={logo ?? LogoSrc} alt="" height={38} />
          </Link>
        </div>
        {userToken ? (
          user ? (
            <div className="Header__menu">
              <div className="Header__avatarWrap">
                <IconButton
                  icon={
                    <Avatar
                      firstName={user?.firstName}
                      lastName={user?.lastName}
                      className="Header__avatar"
                    />
                  }
                  onClick={() => {
                    document.dispatchEvent(hideChatBotEvent)
                    toggleProfile(!isOpenProfile);
                  }}
                />
              </div>
            </div>
          ) : null
        ) : !hideLogin ? (
          <Button
            onClick={() => {
              navigate("/sign-in");
            }}
            size="sm"
            className="Header__checkIn"
          >
            Login
          </Button>
        ) : null}
      </div>
      {userToken ? (
        <Navigation
          className={`Header__profileNav ${
            isOpenProfile ? "Header__profileNav--isOpen" : ""
          }`}
        >
          <div style={styles.buttonProfileWrap}>
            <Button id="signOut" variant="ghost" onClick={resetUser}>
              <span className="lHeadline">Sign out</span>
            </Button>
          </div>
        </Navigation>
      ) : null}
    </header>
  );
};

const styles = {
  buttonMenuWrap: {
    width: 380,
    height: 1000,
    marginTop: 0,
    paddingTop: 20,
    alignSelf: "flex-start",
    paddingLeft: 25,
  },

  buttonProfileWrap: {
    width: 230,
    backgroundColor: "white",
    borderRadius: 15,
    marginTop: 5,
    paddingBottom: 10,
    border: "1px solid black",
    marginRight: "3%",
    alignSelf: "flex-end",
  },

  separator: {
    height: 1,
    width: 340,
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
  },
};

export default Header;
