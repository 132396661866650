import React from 'react'
import './Container.scss'

const Container = ({
  size = 'md',
  className = '',
  isFluid,
  children,
  ...props
}) => {
  return (
    <div
      className={`Container Container--${size} ${
        isFluid ? 'Container--fluid' : ''
      } ${className}`}
      {...props}
    >
      {children}
    </div>
  )
}

export default Container
