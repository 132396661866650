import React from 'react';
import Button from '../Button';
import './IconButton.scss';

const IconButton = ({
  icon,
  size = 'md',
  variant = 'default',
  className = '',
  ...props
}) => {
  return (
    <Button
      variant='ghost'
      className={`IconButton IconButton--size--${size} IconButton--variant--${variant} ${className}`}
      {...props}
    >
      {icon}
    </Button>
  );
};

export default IconButton;
