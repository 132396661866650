import React, {forwardRef} from 'react';
import Box from '../Box';
import Input from '../Input';
import './TextField.scss';

const TextField = forwardRef(({
  as = 'input',
  error = '',
  className = '',
  id,
  label,
  endAdornment,
  ...props
}, ref) => {
  return (
    <Box className={`TextField ${className}`}>
      <label className='TextField__label' htmlFor={id}>
        {label}
      </label>
      <Input
        className={`TextField__input ${
          error ? 'TextField__input--isError' : ''
        }`}
        as={as}
        id={id}
        ref={ref}
        {...props}
      />
      {endAdornment ? (
        <Box className='TextField__endAdornment'>
          {endAdornment}
        </Box>
      ) : null}

      <Box className='TextField__error'>{error}</Box>
    </Box>
  );
});

export default TextField;
