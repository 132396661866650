import React from "react";
import "./Confirmation.scss";
import Header from "../../components/Header";
import Container from "../../components/Container";
import im from "../../components/ChatBot/assets/Benny-Button-favicon.png";
import { useNavigate } from "react-router";

const Confirmation = () => {
  const navigate = useNavigate();
  return (
    <div className="confirmation">
      <Header />
      <Container className="confirmation-container" size="msm">
        <div className="bot-header custom">
          <div className="bot-header-icon">
            <img src={im} alt="ChatBot Icon" width={60} height={60} />
          </div>
          <span className="bot-header-text-invert">
            &nbsp;Wellbeing C🤖mpanion
          </span>
        </div>
        <div className="bot-body">
          <div className="bot-message-wrap">
            <span className="bot-message-confirmation">
              Thanks for signing up to demo the Benny Button Wellbeing Companion.
              <br />
              <br />
              From here, complete a quick 4 question Check In using the sliders or press the 'Be' icon pictured below to chat.
            </span>
          </div>
          <div className="icon-be-wrap">
            <img src={im} alt="ChatBot Icon" />
          </div>
          <div className="bot-message-wrap" style={{ marginTop: 20 }}>
            <span className="bot-message-confirmation">
              Remember, while the companion is learning to provide wellbeing
              support and recommendations, it can't replace professional advice
              or emergency response. If you need that help, please consult a
              healthcare provider or contact relevant services.
            </span>
          </div>
          <div onClick={() => navigate('/')} className="tile tile-custom">
            <span>Understood! Take me to the Companion</span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Confirmation;
