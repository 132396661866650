import React from 'react'
import Heading from '../Heading'
import Slider from '../Slider'
import Text from '../Text'
import './CheckInSlider.scss'

function CheckInSlider({
  name,
  title,
  description,
  defaultValue,
  maxLabel,
  minLabel,
  onChange,
  classNameContainer
}) {
  const handleChange = (value) => {
    onChange?.({ name, value })
  }

  return (
    <div className={`CheckInSlider ${classNameContainer}`}>
      <Heading className="CheckInSlider__title">{title}</Heading>
      <Text variant="md" className="CheckInSlider__description">
        {description}
      </Text>
      <Slider
        minLabel={minLabel}
        maxLabel={maxLabel}
        onChange={handleChange}
        defaultValue={defaultValue}
        className="CheckInSlider__slider"
      />
    </div>
  )
}

export default CheckInSlider
