import React, {useState} from 'react'
import Footer from '../Footer'
import Header from '../Header'
import './Layout.scss'

const Layout = ({ className = '', logo, navigation, hasFooter, children, hideLogin = false }) => {
  const [toggle, doToggle] = useState(false);

  return (
    <div className={`Layout ${className}`}>
      <Header hideLogin={hideLogin} toggle={toggle} className="Layout__header" logo={logo} navigation={navigation} />
      <main onClick={() => doToggle(!toggle)} className="Layout__main">{children}</main>
      {hasFooter ? <Footer /> : null}
    </div>
  )
}

export default Layout
