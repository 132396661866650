import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Form from "../../components/Form";
import Heading from "../../components/Heading";
import Modal from "../../components/Modal";
import { useModal } from "../../components/Modal/Modal";
import Page from "../../components/Page";
import Text from "../../components/Text";
import TextField from "../../components/TextField";
import API from "../../services/api";
import "./ResetPassword.scss";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
});

const ErrorMessage = ({ message }) => {
  return <div className="error-txt">{message}</div>;
};

const ResetPassword = () => {
  const [initData, setInitData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const modal = useModal();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInitData({
      email: location.state?.email ?? "",
    });
  }, [location]);

  const mutation = useMutation({
    mutationFn: (email) => API.resetPassword(email),
    onSuccess: (data) => {
        // Handle success
        setLoading(false)
        setMessage(
          "If you are an existing user we have sent you an email to reset your password"
        );
    },
    onError: (error) => {
        // Handle error
        setLoading(false)
        console.error("Error creating check-in:", error);
    },
  });


  const onSubmit = (values) => {
    setLoading(true)
    const { email } = values;
    mutation.mutate(email);
  };

  return (
    <>
      <Page hasBack hideLogin>
        <Container size="sm">
          <Heading variant="xl" as="h1" className="ResetPassword__title">
            Reset password
          </Heading>

          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initData}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({ values, errors, handleChange, handleSubmit }) => {
              return (
                <Form className="ResetPassword__form" onSubmit={handleSubmit}>
                  <TextField
                    type="email"
                    id="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email}
                    required
                  />
                  {mutation.error ? (
                    <ErrorMessage message={mutation.error?.message} />
                  ) : null}

                  {message ? (
                    <Text
                      variant="sm"
                      className="ResetPassword__successMessage"
                    >
                      {message}
                    </Text>
                  ) : null}
                  <Button
                    type="submit"
                    disabled={loading}
                    className="ResetPassword__submit"
                    size="lg"
                  >
                    Send reset link
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Page>
      <Modal
        {...modal}
        onClose={() => {
          navigate("/sign-in");
        }}
      >
        If you are an existing user we have sent you an email to reset your
        password
      </Modal>
    </>
  );
};

export default ResetPassword;
