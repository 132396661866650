import { useFormik } from "formik";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Form from "../../components/Form";
import Heading from "../../components/Heading";
import Link from "../../components/Link";
import Page from "../../components/Page";
import PasswordField from "../../components/PasswordField";
import TextField from "../../components/TextField";
import { useAuth } from "../../contexts/UserContext";
import "./Signin.scss";
// import { useNavigate } from "react-router-dom";

const Signin = () => {
  const { status, login, error } = useAuth();
  // const history = useNavigate();
  // const previousPathName = history.location.state?.from ?? '';
  const previousPathName = "";
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: ({ email, password }) => {
      login({ email, password, previousPathName });
    },
  });

  navigator.geolocation.getCurrentPosition((position) => {
    const coords = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    localStorage.setItem("coords", JSON.stringify(coords));
  });
  
  return (
    <Page hideLogin>
      <Container size="sm" className="Signin">
        <Heading as="h1" variant="xl" className="Signin__title">
          Sign in
        </Heading>
        <Form className="Signin__form" onSubmit={handleSubmit}>
          <TextField
            type="email"
            id="email"
            label="email"
            value={values.email}
            onChange={handleChange}
            error={error}
            required
          />
          <PasswordField
            id="password"
            label="password"
            value={values.password}
            onChange={handleChange}
            required
          />
          <Link to="/reset-password">Forgotten Password?</Link>
          <Button
            type="submit"
            className="Signin__submit"
            disabled={status === "loading"}
            size="lg"
          >
            Sign in
          </Button>
        </Form>
        <Box className="Signin__footer">
          <Link href="/sign-up">No account yet? Sign up here</Link>
          <Link
            href="https://bennybutton.com/termsandconditions"
            target="_blank"
          >
            Terms and Conditions
          </Link>
        </Box>
      </Container>
    </Page>
  );
};

export default Signin;
