import React from 'react';
import Box from '../Box';
import './Text.scss';

const Text = ({ as = 'div', variant = 'sm', className = '', children, ...props }) => {
  return (
    <Box as={as} className={`Text Text--variant--${variant} ${className}`} {...props}>
      {children}
    </Box>
  );
};

export default Text;
