const getMaxima = (data) => {
  const groupedData = Object.keys(data[0]).reduce((memo, key) => {
    memo[key] = data.map((d) => d[key]);
    return memo;
  }, {});
  return Object.keys(groupedData).reduce((memo, key) => {
    memo[key] = Math.max(...groupedData[key]);
    return memo;
  }, {});
}

const processData = (data) => {
  const maxByGroup = getMaxima(data);
  const makeDataArray = (data) => {
    const refinedData = { ...data };
    delete refinedData['identifier'];
    delete refinedData['email'];
    delete refinedData['uid'];
    delete refinedData['checkinDatetime'];
    delete refinedData['token'];
    delete refinedData['checkinLatitude'];
    delete refinedData['checkinLongitude'];
    delete refinedData['checkinSource'];
    return Object.keys(refinedData).map((key) => {
      return { x: key, y: Math.max(0.05, refinedData[key] / maxByGroup[key]) };
    });
  };
  return data.map((datum) => makeDataArray(datum));
}

const exported = {
  getMaxima,
  processData
}

export default exported;