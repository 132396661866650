import React from "react";
import Heading from "../Heading";
import Progress from "../Progress";
import Text from "../Text";
import "./ReportProgress.scss";
import isMobile from "../../utils/isMobile";
//critical to sort data
const wellbeingAndPerformance = [
  {
    label: "Wellbeing",
    points: [
      {
        point: [0, 66],
        color: "red",
      },
      {
        point: [67, 77],
        color: "amber",
      },
      {
        point: [78, 100],
        color: "green",
      },
    ],
  },
  {
    label: "Performance",
    points: [
      {
        point: [0, 59],
        color: "red",
      },
      {
        point: [60, 75],
        color: "amber",
      },
      {
        point: [76, 100],
        color: "green",
      },
    ],
  },
  {
    label: "all",
    points: [
      {
        point: [0, 64],
        color: "red",
      },
      {
        point: [65, 75],
        color: "amber",
      },
      {
        point: [76, 100],
        color: "green",
      },
    ],
  },
];

const getProgressColor = (value, title = "all") => {
  const item = wellbeingAndPerformance.filter((val) => val.label === "all");
  const color = item[0].points.filter(
    (itemPoint) => value >= itemPoint?.point[0] && value <= itemPoint?.point[1]
  );
  return color[0]?.color;
};

const ReportProgress = ({
  title,
  description,
  value,
  disabled,
  isAnimated,
}) => {
  return (
    <div
      className={`ReportProgress ${disabled ? "ReportProgress--disabled" : ""}`}
    >
      <div className="ReportProgress__content">
        <Text variant={isMobile() ? "sm--bold" : "md--bold"}>{title}</Text>
        {!isMobile() ? <Text variant="md">{description}</Text> : null}
      </div>
      <div className="ReportProgress__figure">
        <Progress
          value={value}
          color={disabled ? "gray" : getProgressColor(Math.round(value), title)}
        />
        <div
          className={`reportFlex ${
            isAnimated ? "ReportProgress__animated" : ""
          }`}
        >
          {isMobile() ? (
            <span className="xlHeadline">{Math.round(value)}</span>
          ) : (
            <Heading variant="xxl">{Math.round(value)}</Heading>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportProgress;
