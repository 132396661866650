import React from 'react'
import {useNavigate} from 'react-router-dom'
import {ReactComponent as IconBack} from '../../images/arrow-back.svg'
import Box from '../Box'
import Button from '../Button'
import Container from '../Container'
import Layout from '../Layout'
import './Page.scss'

function Page({
  className = '',
  logo,
  navigation,
  isFluid,
  hasFooter,
  hasBack,
  children,
  hideLogin
}) {
  const navigate = useNavigate()

  return (
    <Layout hideLogin={hideLogin} logo={logo} hasFooter={hasFooter} navigation={navigation}>
      <Container isFluid={isFluid}>
        <Box className={`Page ${className}`}>
          {hasBack ? (
            <Button
              variant="ghost"
              className="Page__backLink"
              onClick={() => {
                navigate(-1)
              }}
            >
              <IconBack /> <span>Back</span>
            </Button>
          ) : null}
          <Box className="Page__content">{children}</Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default Page
