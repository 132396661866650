import React from 'react';
import './Navigation.scss';

const Navigation = ({ className = '', children }) => {
  return (
    <nav className={`Navigation ${className}`}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { className: 'Navigation__item' })
      )}
    </nav>
  );
};

export default Navigation;
