export default class Validators {
    static PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3}$/im;
    static EMAIL_REGEX =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    static PASSWORD_REGEX =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*/])(?=.{8,})/;
    static PASSWORD_REGEX_MIN_SIZE_8 =
        /^(?=.{8,})/;
    static NAME_REGEX = /(\w.+\s).+/i;
  
    static isEmailValid(email) {
      const reg = Validators.EMAIL_REGEX;
      return reg.test(email);
    }
  
    static isPhoneValid(inputtxt) {
      const phoneno = Validators.PHONE_REGEX;
      return phoneno.test(inputtxt);
    }
  
    static isPasswordValid(pass) {
      const regex = Validators.PASSWORD_REGEX;
      return regex.test(pass);
    }
  
    static isLengthRangeValid(text, minLength, maxLength) {
      if (minLength && maxLength) {
        return text && text.length >= minLength && text.length <= maxLength;
      }
      if (minLength) {
        return text && text.length >= minLength;
      }
      if (maxLength) {
        return text && text.length <= maxLength;
      }
      return false;
    }
  
    static isRequired(text) {
      if (text) {
        if (typeof text === 'string') {
          return text.trim().length > 0;
        }
        return true;
      }
      return false;
    }
  
    static isNumberOnly(text) {
      const regex = /\D/g;
      return !regex.test(text);
    }
  }
  