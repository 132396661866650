import React, { useContext, useState, useEffect, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import API, { getAuthToken } from "../services/api";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("idle");
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [userToken, setUserToken] = useState(getAuthToken());
  const {
    data: userData,
    isLoading,
    refetch: refetchUser,
    isError: getUserError,
  } = useQuery({
    queryKey: ["user"],
    queryFn: async () => API.getUser({ userToken }),
    enabled: !!userToken,
    refetchOnWindowFocus: true,
    retry: 1,
  });

  const login = ({ email, password, isSignUp = false }, { redirectOnSuccess = true } = {}) => {
    setStatus("loading");
    setError(null);

    API.login(email, password)
      .then((response) => {
        const idToken = response?.data?.idToken;

        setStatus("success");

        API.setAuthorizationHeader(idToken);

        setUserToken(idToken);

        if (redirectOnSuccess) {
          navigate(isSignUp ? "/confirmation" : "/");
        }
      })
      .catch((err) => {
        setStatus("error");

        if (err.statusCode === 401) {
          setError("Email or password is incorrect");
        } else {
          setError(err.message);
        }
      });
  };

  const resetUser = useCallback(() => {
    API.sendOSInfo("LOGOUT");
    API.removeAuthorizationHeader();
    setUser(undefined);
    setUserToken(undefined);
    window.location.reload();
  }, [setUser, setUserToken]);

  useEffect(() => {
    if (userToken) {
      API.setAuthorizationHeader(userToken);
    }
  }, [userToken]);

  useEffect(() => {
    if (userData?.data?.body) {
      setUser(userData.data.body);
    }
  }, [userData]);

  useEffect(() => {
    if (getUserError) {
      resetUser()
    }
  }, [getUserError, resetUser]);

  return (
    <UserContext.Provider
      value={{
        isLoading,
        user,
        userToken,
        setUser,
        setUserToken,
        resetUser,
        refetchUser,
        login,
        error,
        status,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(UserContext);
};
