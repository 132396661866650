import {useMutation} from "@tanstack/react-query";
import API from "../services/api";

export default function useCheckIn() {
    const mutation = useMutation({
        mutationFn: (checkinValues) => API.createCheckIn(checkinValues),
        onSuccess: (data) => {
            // Handle success
            console.log("Check-in created successfully:", data?.data);
        },
        onError: (error) => {
            // Handle error
            console.error("Error creating check-in:", error);
        },
    });

    const { data, mutate: createCheckIn, isSuccess, error} = mutation;

    return {
        data,
        createCheckIn,
        isSuccess,
        error
    };
}