import React from 'react'
import Box from '../Box'
import './Button.scss'

const Button = React.forwardRef(
  (
    {
      as = 'button',
      type = 'button',
      size = 'md',
      variant = 'primary',
      palette = 'default',
      className = '',
      isFull,
      children,
      isActive,
      ...props
    },
    ref,
  ) => {
    const classNames = [
      `Button Button--size--${size}`,
      `Button--variant--${variant}`,
      `Button--palette--${palette}`,
      isActive ? 'Button--isActive' : '',
      isFull ? 'Button--isFull' : '',
      className,
    ]
    return (
      <Box
        as={as}
        type={as === 'button' ? type : undefined}
        className={classNames.join(' ')}
        ref={ref}
        {...props}
      >
        {children}
      </Box>
    )
  },
)

export default Button
